export const translate = {
    es:{
        hola:"Hola",
        asignar_cajas:'Asignar cajas',
        perfil:'Perfil',
        nueva_marca:"Nueva marca",
        clave:"Contraseña",
        clave_actual:"Contraseña actual",
        clave_nueva: "Contraseña nueva",
        repetir_clave_nueva: "Repetir contraseña nueva",
        almenos8:"Al menos 8 caracteres",
        pagar_comision:'Pagar comisión',
        nopagado:'No pagado',
        seleccione_estado:'Seleccione estado',
        pagar_todo:'Pagar todo',
        actualizar:"Actualizar",
        generales:"Generales",
        recordar:'Recordar',
        listado:"Listado",
        pedidos:"Pedidos",
        nuevo_color:'Nuevo color',
        tipo_pago:'Tipo de pago',
        seleccione_tipo_pago:'Seleccione el  tipo',
        empleado:'Empleado',
        producto:'Producto',
        actividad:'Actividad',
        nombre_completo:"Nombre completo",
        nuevo:"Nuevo",
        seleccione_deposito: "Seleccione depósito",
        datos_proveedor:"Datos de proveedor",
        seleccione_comprobante:"Seleccione comprobante",
        credito:"Crédito",
        fecha_pago:"Fecha de pago",
        factura_credito:"Factura crédito",
        factura_contado:"Factura contado",
        seleccione_proveedor:"Seleccione proveedor",
        monto:"Monto",
        reportes:'Reportes',
        busca_por_cliente:'Busca por cliente',
        nueva_nota:'Nueva nota',
        notas_subtitle:'Modulo de notas generadas',
        ver_lista:'Ver lista',
        usuario_relacionado:'Usuario relacionado',
        q_desea_finalizar_venta_sin_cliente: 'Desea finalizar la nota sin cliente?',
        q_desea_finalizar_nota: 'Desea finalizar la nota?',
        hacer_nota:'Hacer nota',
        asignar_usuarios:'Asignar usuarios',
        cerrar_tran_no_efectivo: "Cerrar transacciones sin efectivo",
        facebook:"Facebook",
        instagram:"Instagram",
        colores:"Colores",
        asignaciones:'Asignaciones',
        sitio_web: "Sitio web",
        configuraciones:"Configuraciones",
        datos_web: "Datos web",
        redes_sociales: "Redes sociales",
        site_name: "Nombre de sitio",
        site_title:"Titulo del sitio",
        site_description:"Descripcion del sitio",
        moneda_que_muestra:"Moneda que se muestra en el sitio",
        logo_url:"URL de logo",
        whatsapp:"Whatsapp",
        tel:'Teléfono',
        montos:"Montos",
        seleccione_empleado:"Seleccione empleado",
        nro_comprobante:"Nro comprobante",
        datos_comprobante:"Datos de comprobante",
        costo:"Costo",
        borrar_item:"Borrar item",
        val_anteriores:"Valores anteriores",
        stock:"Stock",
        stock_comprado:"Stock comprado",
        subir_logo:"Subir logo",
        costo_anterior:"Costo anterior",
        codigo_del_producto:"Código de Producto", 
        insertar_producto_factura:"Insertar Producto a factura",
        insertar:"Insertar",
        precio_balcon:"Precio balcon",
        precio_mayorista:"Precio mayorista",
        precio_con_descuento:"Precio con descuento",
        finalizar_compras:"Finalizar compra",
        volver_atras:"Volver atras",
        no_relacion_con_caja: "Esa caja no esta relacionada con ninguna factura",
        monedas_de_caja:"Monedas de caja",
        fecha_nacimiento:"Fecha de nacimiento",
        selecciona_moneda:"Selecciona moneda",
        complete_datos_correctamente:"Complete los datos correctamente",
        movimiento_registrado:"Movimiento de caja registrado",
        cantidad_actual:"Cantidad actual",
        backup:"BackUp",
        ver_mas:"Ver más",
        lucro:"Lucro",
        total_ventas:"Total ventas",
        descuento:"Descuento",
        config_empresa: "Config. de empresa",
        confirmar_cierre:"Confirmar cierre",
        cierre_de_caja:"Cierre de caja",
        resumen_cierre_caja:"Resumen de cierre de caja",
        usuario_responsable:"Usuario responsable",
        fecha_apertura:"Fecha de apertura",
        total_ingreso:"Total ingreso",
        total_egreso:"Total egreso",
        total_sin_efectivo:"Total sin efectivo",
        porcentaje_descuento:"Porcentaje de descuento",
        descripcion:"Descripción",
        total_declarado:"Total declarado",
        precios:"Precios",
        precio_del_producto:"Precio del producto",
        generar_precio_porcentaje:"Generar precios por %",
        Error_404:"Error 404",
        informes_productos_vendidos:"Informes de productos vendidos",
        sub_informes_productos_vendidos:"Lista e informes de productos vendidos",
        apertura:"Apertura",
        Informes_Generales:"Informes Generales",
        Informes_Productos:"Informes Productos",
        ingreso:"Ingreso",
        egreso:"Egreso",
        cierre:"Cierre",
        presione_mas_detalles:"Presione para ver más detalles",
        habilitar_nueva_caja:"Habilitar y agregar nueva caja",
        salir:"Salir",
        no_autorizado:"No tienes permiso para este sitio",
        q_volver_casa:"Porque no vuelves a casa?",
        q_desea_borrar:"Desea borrar?",
        volver_a_casa:"Volver a casa",
        licencia_valida_hasta:"Licencia válida hasta",
        categoria_padre:"Categoría padre",
        ninguno:"Ninguno",
        datos:"Datos",
        imagenes:"Imagenes",
        valores_stock:"Valores y Stock",
        nuevo_deposito:"Nuevo depósito",
        cambiar:"Cambiar",
        rol_empleado:"Rol de empleado",
        seleccionar:"Seleccionar",
        salario:"Salario",
        tipo_salario:"Tipo de salario",
        jornales:"Jornales",
        comisiones:"Comisiones",
        lista_empleados:"Lista de empleados",
        actualizado_correctamente:"Actualizado correctamente",
        agregado_correctamente: "Agregado correctamente",
        colores_actuales:"Colores actuales",
        seleccionar_colores:"Seleccionar color",
        inicio:"Inicio",
        tipo_producto:"Tipo producto",
        informe:"Informe",
        informes:"Informes",
        mantenimiento:"Mantenimiento",
        agendas:"Agendas",
        ventas:"Ventas",
        nueva_venta:"Vender",
        compras:"Compras",
        arqueos:"Arqueos",
        ajustes:"Ajustes",
        turnos:"Turnos",
        guardar:"Guardar",
        no_se_puede_borrar:"No se puede borrar",
        agenda:"Agenda",
        notas:"Notas",
        entregas:"Entregas",
        nuevo_talonario:"Nuevo talonario",
        articulo:"Artículo",
        servicio:"Servicio",
        registrar:"Registrar",
        registrado_correctamente:"Registrado correctamente",
        registro_facturas:"Reg. de facturas",
        registro_factura_descripcion:"Facturas habilitadas por la set",
        facturas:"Facturas",
        lista_facturas:"Facturas",
        buen_dia:"Buen día",
        generar_codigo:"Generar código",
        buenas_tardes:"Buenas tardes",
        buenas_noches:"Buenas noches",
        q_desea_cerrar_sesion:"Desea cerrar la sesion?",
        q_borrar:"Borrar?",
        no_puede_borrar_productos:"No se puede borrar porque hay productos que tiene este proveedor",
        ordenar:"Ordenar",
        borrado_correctamente:"Borrado correctamente",
        q_desea_borrar_imagen:"Desea borrar la imagen?",
        cliente_existente_doc:"Ese cliente ya existe con ese documento",
        borrar:"Borrar",
        categoria:"Categoría",
        categorias:"Categorías",
        proveedores:"Proveedores",
        lista_proveedores:"Lista de proveedores",
        marcas:"Marcas",
        apartados:"Apartados",
        lista_apartados:"Módulo de apartado de stock de los dépositos",
        apartar_producto:"Apartar producto",
        deposito:"Depósito",
        lista_depositos:"Lista de depósito",
        depositos:"Depósitos",
        transferencias:"Transferencias",
        inventario:"Inventario",
        mov__caja:"Mov. de cajas",
        movimientos_caja:"Movimientos de cajas",
        registros__mov:"Registro de mov.",
        recibido:"Recibo",
        cuentas:"Cuentas",
        mantenimientos:"Mantenimientos",
        clientes:"Clientes",
        cliente_nuevo:"Cliente nuevo",
        lista_clientes:"Lista de clientes",
        cliente:"Cliente",
        empleados:"Empleados",
        monedas:"Monedas",
        unidad_medida:"Medidas",
        impuestos:"Impuestos",
        tamano_fuente_general:"Tamaño de fuente general",
        tamano_fuente_menu:"Tamaño de fuente menú",
        datos_empresa:"Empresa",
        tema_y_colores:"Tema",
        codigo_existe:"Ese código del producto ya existe",
        editar:"Editar",
        editar_producto:"Editar producto",
        rellene_codigo:"Rellene el código",
        rellene_nombre:"Rellene el nombre del producto",
        rellene_campos:"Rellene todos los campos",
        volver:"Volver",
        info_y_ayuda:"Info y ayuda",
        entrada:"Entrada",
        salida:"Salida",
        codigo:"Código",
        cantidad:"Cantidad",
        productos_apartados:'Productos apartados',
        totalCaja:"Total caja",
        apartado_por:"Apartado por",
        productos:"Productos",
        lista_productos:"Lista de productos",
        precio:"Precio",
        cancelar:"Cancelar",
        ingrese_nombre_caja:"Ingrese nombre de caja",
        seleccione_usuario:"Seleccione un usuario",
        seleccione_moneda:"Seleccione una moneda",
        monto_inicial_negativo:"El monto inicial no puede ser negativo",
        q_desea_eliminar:"Desea eliminar?",
        warn_no_podra_recuperar:"No podra recuperar los datos!",
        ok:"Ok",
        usuario:"Usuario",
        usuarios:"Usuarios",
        lista_de_usuarios:"Lista de usuarios",
        ingresar:"Ingresar",
        cargando:"Cargando",
        nombre:"Nombre",
        doc:"Doc",
        contacto:"Contacto",
        recordarme:"Recordarme",
        apellido:"Apellido",
        contrasena:"Contraseña",
        a:"A",
        nro_telefono:"Número de teléfono",
        en_mm:"En mm",
        dim_ticket:"Dimensión de ticket",
        direccion:"Dirección",
        correo_electronico:"Correo Electrónico",
        casual:"Casual",
        minorista:"Minorista",
        mayorista:"Mayorista",
        telefono:"Teléfono",
        proveedor:"Proveedor",
        documento:"Documento",
        ruc_de_empresa:"Documento o Ruc de empresa",
        propietario:"Propietario de empresa",
        nombre_de_empresa:"Nombre de empresa",
        nombre_de_caja:"Nombre de caja",
        estado:"Estado",
        abierto:"Abierto",
        no:"No",
        complete_campo_correctamente:"Complete el campo correctamente",
        _abreviatura_moneda:"Abre. moneda",
        nombre_moneda:"Nombre de moneda",
        valor:"Valor",
        padre:"Padre",
        id:"ID",
        ingrese_codigo_pulse_enter:"Ingrese el código y presione Enter o el botón",
        agregar_item:"Agregar item",
        finalizar:"Finalizar",
        esperar:"Esperar",
        anotar:"Anotar",
        genera_un_pedido:"Genera un pedido de nota",
        ayuda:"Ayuda",
        tipo:"Tipo",
        tipo_categoria:"Tipo categoria",
        error:"Error",
        notas_pedidos:"Pedidos",
        cambiar_cotizacion:"Cambiar cotizacion",
        cotizacion:"Cotización",
        cotizaciones_monedas:"Cotizaciones de monedas",
        moneda_principal:"Moneda principal",
        cerrado:"Cerrado",
        cerrar:"Cerrar",
        abrir:"Abrir",
        buscar:"Buscar",
        agregar:"Agregar",
        agregar_nuevo:"Agregar nuevo",
        caja:"Caja",
        cajas:"Cajas",
        siguiente:"Siguiente",
        atras:"Atras",
        habilitacion_y_apertura_de_cajas:"Habilitacion y apertura de cajas",
        caja_abierta_correctamente:"Caja abierta correctamente",
        caja_cerrada_correctamente: "Caja cerrada correctamente",
        opciones:"Opciones",
        lista_de_cajas:"Lista de cajas",
        sin_registros:"Sin registros",
        asignado_a:"Asignado a",
        monto_inicial:"Monto inicial",
        asignar_usuario:"Asignar a un usuario",
        monto_actual_efectivo:"Monto actual efectivo",
        hacer_transferencia:"Hacer transferencia",
        creacion_apertura_caja:"Creación, habilitación y apertura de caja",
        agregado_habilitado_correctamente:"Agregado y habilitado correctamente",
        indique_caja_a_transferir:"Indique la caja a la que desea transferir",
        no_hay_suficientes_fondos_en_caja:"En la caja no hay suficientes fondos",
        transferencia_caja_a_caja:"Transferencia de caja en caja",
        
        monto_a_transferir:"Monto a transferir",
        transferir_a:"Transferir a",
        transferir:"Transferir",
        transferido_correctamente: "Transferido correctamente",
        transferencia_de_caja:"Transferencia de caja",
        editado_correctamente:"Editado correctamente",
        porcentaje:"porcentaje",
        moneda:"Moneda",
        registrar_movimiento:"Registrar movimiento",
        seleccione_caja:"Seleccione caja",
        selecciona_registro:"Selecciona registro",
        seleccione_movimiento:"Seleccione movimiento",
        monto_movimiento:"Monto de movimiento",
        obs_detalles:"Observaciones y detalles",
        monto_efe_caja:"Monto efectivo en caja",
        responsable:"Responsable",
        fecha:"Fecha",
        sin_efectivo:"Sin efectivo",
        desde:"Desde",
        hasta:"Hasta",
        todos:"Todos",
        filtrar:"Filtrar",
        ver:"Ver",
        a_cobrar:'A cobrar',
        a_pagar:'A pagar',
        detalles_movimiento:"Detalles de movimiento",
        producto_chequeado:"Producto chequeado",
        entregas_pendientes:"Entregas pendientes",
        long_movimiento_caja:"En este módulo se pueden visualizar todos los movimientos de las cajas por fecha",
        total_movimiento:"Total de movimiento",
        complete_nombre:"Complete el nombre por favor",
        editar_nombre:"Editar nombre",
        nombre_caja:"Nombre de caja",
        long_no_negativo:"El monto inicial no puede ser negativo",
        hacer_apertura:"Hacer apertura",
        long_ultimo_monto:"Monto de último cierre valor en efectivo",
        presione_abrir:"Presione abrir",
        abrir_caja:"Abrir caja",
        confirmar:"Confimar",
        total_en_efectivo:"Total en efectivo",
        arqueo_caja:"Arqueo de caja",
        billetes_y_monedas:"Billetes y monedas",
        egresos:"Egresos",
        neto:"Neto",
        recibo:"Recibo",
        consultar:"Consultar",
        nro:"Nro",
        total:"Total",
        opciones_filtro:"Opciones de filtro",
        buscar_por_nro:"Buscar por nro",
        lista_categorias:"Lista de categorias",
        volver_arqueo:"Volver al arqueo",
        cierre_caja_resumen:"Cierre de caja resumen",
        q_desea_cerrar: "Desea cerrar ",
        no_puede_eliminarte:"No puedes eliminarte a ti mismo",
        long_no_farzante:"Debemos estar seguros de que no eres un farzante",
        confirmar_contra:"Confirmar contraseña",
        nuevo_usuario:"Nuevo usuario",
        usuario_logueo:"Usuario de logueo",
        repetir_contrasena:"Repetir contraseña",
        email:"Correo",
        rol_user:"Rol de usuario",
        select_rol:"Seleccione rol",
        ya_existe_usuario:"Ya existe ese usuario",
        password_no_iguales:"Contraseñas no son iguales",
        error_pass:"Error de contraseña",
        edit_usuario:"Editar usuario",
        cambiar_pass:"Cambiar contraseña",
        no_existe_producto:"No existe ese producto",
        old_pass:"Contraseña antigua",
        long_contrasena_cambiado: "Contraseña cambiada exitosamente, para mayor seguridad, cierre la sesión e inicie nuevamente.",
        permisos:"Permisos",
        imprimir:"Imprimir",
        imprimir_nota_pedido:"Imprimir nota de pedido",
        nota_de_pedido:"Nota de pedido",
        nro_de_pedido:"Nro de pedido",
        cuentas_a_pagar:"Cuentas a pagar",
        hacer_compras: "Hacer compras",
        lista_compras: "Lista de compras",
        listas_compras:"Lista de compras a pagar",
        cuentas_a_cobrar:"Cuentas a cobrar",
        listas_cuentas_cobrar:"Lista de ventas/facturas a cobrar",
        cuenta_a_pagar:"Cuenta a pagar",
        pagar:"Pagar",
        finalizar_venta:"Finalizar venta",
        volver_factura:"Volver a factura",
        pagado:"Pagado",
        pendiente:"Pendiente",
        detalles:"Detalles",
        cantidad_efectivo:"Cantidad en efectivo",
        cantidad_sin_efectivo:"Cantidad sin efectivo",
        registrar_cliente:"Registrar cliente",
        cobrar:"Cobrar",
        formas_de_pago:"Formas de pago",
        monto_total:"Monto total",
        observaciones:"Observaciones",
        monto_recibido:"Monto recibido",
        monto_faltante:"Monto faltante",
        monto_a_cobrar:"Monto a cobrar",
        pagado_correctamente:"Pagado correctamente",
        cobrado_correctamente:"Cobrado correctamente",
        seleccione_vendedor:"Seleccione vendedor",
        recibo_nro:"Recibo nro",
        iniciar:"Iniciar",
        hacer_venta:"Hacer venta",
        listas_facturas:"Listas de facturas",
        volver_menu_principal:"Volver menú principal"
    },

    en:{
        salir:"Logout",
        no_autorizado:"No tienes permiso para este sitio",
        q_volver_casa:"Porque no vuelves a casa?",
        q_desea_borrar:"Do you want to delete?",
        volver_a_casa:"Get back to home",
        licencia_valida_hasta:"Licencia válida hasta",
        categoria_padre:"Categoría padre",
        ninguno:"None",
        datos:"Datos",
        imagenes:"Imagens",
        valores_stock:"Valores y Stock",
        nuevo_deposito:"Nuevo depósito",
        cambiar:"Cambiar",
        rol_empleado:"Rol de empleado",
        seleccionar:"Seleccionar",
        salario:"Salario",
        tipo_salario:"Tipo de salario",
        jornales:"Jornales",
        comisiones:"Comisiones",
        lista_empleados:"Lista de empleados",
        actualizado_correctamente:"Actualizado correctamente",
        agregado_correctamente: "Agregado correctamente",
        colores_actuales:"Colores actuales",
        seleccionar_colores:"Seleccionar color",
        inicio:"Home",
        tipo_producto:"Tipo producto",
        informe:"Informe",
        informes:"Informes",
        mantenimiento:"Mantenimiento",
        agendas:"Agendas",
        ventas:"Sales",
        nueva_venta:"New sale",
        compras:"Compras",
        arqueos:"Arqueos",
        ajustes:"Settings",
        turnos:"Turnos",
        guardar:"Guardar",
        no_se_puede_borrar:"No se puede borrar",
        agenda:"Agenda",
        notas:"Notes",
        entregas:"Entregas",
        nuevo_talonario:"Nuevo talonario",
        articulo:"Artículo",
        servicio:"Service",
        registrar:"Registrar",
        registrado_correctamente:"Registrado correctamente",
        registro_facturas:"Registro de facturas",
        registro_factura_descripcion:"Facturas habilitadas por la set",
        facturas:"Facturas",
        lista_facturas:"Facturas",
        buen_dia:"Good morning",
        generar_codigo:"Generar código",
        buenas_tardes:"Good afternoon",
        buenas_noches:"Good night",
        q_desea_cerrar_sesion:"Desea cerrar la sesion?",
        q_borrar:"Borrar?",
        no_puede_borrar_productos:"No se puede borrar porque hay productos que tiene este proveedor",
        ordenar:"Ordenar",
        borrado_correctamente:"Borrado correctamente",
        q_desea_borrar_imagen:"Desea borrar la imagen?",
        cliente_existente_doc:"Ese cliente ya existe con ese documento",
        borrar:"Delete",
        categoria:"Category",
        categorias:"Categories",
        proveedores:"Proveedores",
        lista_proveedores:"Lista de proveedores",
        marcas:"Brands",
        apartados:"Apartados",
        lista_apartados:"Módulo de apartado de stock de los dépositos",
        apartar_producto:"Apartar producto",
        deposito:"Store",
        lista_depositos:"Lista de depósito",
        depositos:"Stores",
        transferencias:"Transferencias",
        inventario:"Inventario",
        mov__caja:"Mov. de cajas",
        movimientos_caja:"Movimientos de cajas",
        registros__mov:"Registros de mov.",
        cuentas:"Cuentas",
        mantenimientos:"Mantenimientos",
        clientes:"Clientes",
        cliente_nuevo:"New customer",
        lista_clientes:"List of customers",
        cliente:"Customer",
        empleados:"Empleados",
        monedas:"Monedas",
        unidad_medida:"Unidad de medida",
        impuestos:"Taxes",
        tamano_fuente_general:"Tamaño de fuente general",
        tamano_fuente_menu:"Tamaño de fuente menú",
        datos_empresa:"Datos de empresa",
        tema_y_colores:"Theme and color",
        codigo_existe:"Ese código del producto ya existe",
        editar:"Edit",
        editar_producto:"Edit product",
        rellene_codigo:"Rellene el código",
        rellene_nombre:"Rellene el nombre del producto",
        rellene_campos:"Rellene todos los campos",
        volver:"Back",
        info_y_ayuda:"Info y ayuda",
        codigo:"Code",
        cantidad:"Quantity",
        apartado_por:"Apartado por",
        productos:"Products",
        lista_productos:"List of products",
        precio:"Price",
        cancelar:"Cancel",
        ingrese_nombre_caja:"Ingrese nombre de caja",
        seleccione_usuario:"Seleccione un usuario",
        seleccione_moneda:"Seleccione una moneda",
        monto_inicial_negativo:"El monto inicial no puede ser negativo",
        q_desea_eliminar:"Desea eliminar?",
        warn_no_podra_recuperar:"No podra recuperar los datos!",
        ok:"Ok",
        usuario:"User",
        usuarios:"Users",
        lista_de_usuarios:"Lista de usuarios",
        ingresar:"Login",
        cargando:"Loading",
        nombre:"Name",
        doc:"Doc",
        contacto:"Contact",
        recordarme:"Remember me",
        apellido:"Last name",
        contrasena:"Password",
        a:"to",
        nro_telefono:"Number phone",
        en_mm:"En mm",
        dim_ticket:"Dimensión de ticket",
        direccion:"Address",
        correo_electronico:"Email",
        casual:"Casual",
        minorista:"Minorista",
        mayorista:"Mayorista",
        telefono:"Phone",
        proveedor:"Proveedor",
        documento:"Document",
        ruc_de_empresa:"Document or Ruc de empresa",
        propietario:"Propietario de empresa",
        nombre_de_empresa:"Nombre de empresa",
        nombre_de_caja:"Nombre de caja",
        estado:"State",
        abierto:"Opened",
        no:"No",
        complete_campo_correctamente:"Complete el campo correctamente",
        _abreviatura_moneda:"Abre. moneda",
        nombre_moneda:"Nombre de moneda",
        valor:"Value",
        padre:"Father",
        id:"ID",
        ingrese_codigo_pulse_enter:"Ingrese el código y presione Enter o el botón",
        agregar_item:"Agregar item",
        finalizar:"Finalizar",
        esperar:"Wait",
        anotar:"Anotar",
        genera_un_pedido:"Genera un pedido de nota",
        ayuda:"Help",
        tipo:"Tipo",
        tipo_categoria:"Tipo categoria",
        error:"Error",
        notas_pedidos:"Notas de pedidos",
        cambiar_cotizacion:"Cambiar cotizacion",
        cotizacion:"Cotización",
        cotizaciones_monedas:"Cotizaciones de monedas",
        moneda_principal:"Moneda principal",
        cerrado:"Cerrado",
        cerrar:"Cerrar",
        abrir:"Abrir",
        buscar:"Search",
        agregar:"Add",
        agregar_nuevo:"Add new",
        caja:"Caja",
        cajas:"Cajas",
        siguiente:"Next",
        atras:"Atras",
        habilitacion_y_apertura_de_cajas:"Habilitacion y apertura de cajas",
        caja_abierta_correctamente:"Caja abierta correctamente",
        opciones:"Options",
        lista_de_cajas:"Lista de cajas",
        sin_registros:"Sin registros",
        asignado_a:"Asignado a",
        monto_inicial:"Monto inicial",
        asignar_usuario:"Asignar a un usuario",
        monto_actual_efectivo:"Monto actual efectivo",
        hacer_transferencia:"Hacer transferencia",
        creacion_apertura_caja:"Creación, habilitación y apertura de caja",
        agregado_habilitado_correctamente:"Agregado y habilitado correctamente",
        indique_caja_a_transferir:"Indique la caja a la que desea transferir",
        no_hay_suficientes_fondos_en_caja:"En la caja no hay suficientes fondos",
        transferencia_caja_a_caja:"Transferencia de caja en caja",
        monto:"Monto",
        monto_a_transferir:"Monto a transferir",
        transferir_a:"Transferir a",
        transferir:"Transferir",
        transferido_correctamente: "Transferido correctamente",
        transferencia_de_caja:"Transferencia de caja",
        editado_correctamente:"Edited correctly",
        nuevo_usuario:"Nuevo usuario"
    }

}